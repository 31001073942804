import { useEffect } from 'react';
import useDarkMode from 'use-dark-mode';

export const useUtterance = (selector) => {
  const isDark = useDarkMode(false).value;

  useEffect(() => {

    const container = document.querySelector(selector);
    if (container) {
      container.innerHTML = '';
      const utteranceScript = document.createElement('script');
      utteranceScript.src = 'https://utteranc.es/client.js';
      utteranceScript.async = true;
      utteranceScript.crossOrigin = 'anonymous';
      utteranceScript.setAttribute('issue-term', 'pathname');
      utteranceScript.setAttribute('theme', isDark ? 'dark-blue' : 'github-light');
      utteranceScript.setAttribute('repo', 'XKojiMedia/xkojimedia.github.io');

      container.appendChild(utteranceScript);
  
      return () => {
        if (container && container.contains(utteranceScript)) {
          container.removeChild(utteranceScript);
        }
      };
    }
  }, [ selector, isDark ]);
};
