import React from 'react';

import './share-article.css';

import FacebookIcon from '../../svg/facebook.svg';
import TwitterIcon from '../../svg/twitter.svg';
import LinkedinIcon from '../../svg/linkedin.svg';
import WhatsappIcon from '../../svg/whatsapp.svg';
// import shareIcon from '../../svg/share.svg';

const text = url => `Check out this article on xkojidev!`;
const facebookShareUrl = (url) => `https://www.facebook.com/sharer/sharer.php?u=${url}`;
const twitterShareUrl = (url) => `https://twitter.com/share?url=${url}&text=${text(url)}&hashtags=xkojidev`;
const linkedinShareUrl = (url) => `https://linkedin.com/shareArticle?url=${url}&mini=true&summary=${text(url)}`;
const whatsappShareUrl = (url) => `https://api.whatsapp.com/send?text=${text(url)} - ${url}`;

const ShareArticle = ({ baseUrl, slug }) => {
  const url = `${baseUrl}${slug}`;

  return (
    <div className='share-article__wrapper'>
      <div className='share-article__label'>
        Share:
      </div>
      <a className='share-article__link' href={facebookShareUrl(url)} target='_blank' rel='noopener noreferrer'>
        <FacebookIcon />
      </a>
      <a className='share-article__link' href={twitterShareUrl(url)} target='_blank' rel='noopener noreferrer'>
        <TwitterIcon />
      </a>
      <a className='share-article__link' href={linkedinShareUrl(url)} target='_blank' rel='noopener noreferrer'>
        <LinkedinIcon />
      </a>
      <a className='share-article__link' href={whatsappShareUrl(url)} target='_blank' rel='noopener noreferrer'>
        <WhatsappIcon />
      </a>
      {/* <a className='share-article__link' href={whatsappShareUrl(url)} target='_blank' rel='noopener noreferrer'>
        <img src={shareIcon} alt='share' />
      </a> */}
    </div>
  );
};

export default ShareArticle;
