import React from "react";
import { graphql } from "gatsby";
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm, scale } from "../utils/typography";
import SubscribeEmail from "../components/subscribe-email";
import ShareArticle from "../components/share-article/share-article";
import { useUtterance } from "../utils/hooks/utterance";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const postImage = post.frontmatter.image ? post.frontmatter.image.publicURL : undefined;

  useUtterance('#comments-section');

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={postImage}
        isArticle={true}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        {/* <section className='blog-post__toc' dangerouslySetInnerHTML={{ __html: post.tableOfContents }}/> */}
        <section className='blog-post__content' dangerouslySetInnerHTML={{ __html: post.html }} />
        <section>
          <hr></hr>
          <p>Did you find this useful? Do you think there are better approaches to take, or questions? You can reach out to me on twitter <a href="https://twitter.com/imolorhe">@imolorhe</a>.</p>
          <blockquote style={{ fontSize: '16px' }}>
            <p>
              Write about what you learn. It pushes you to understand topics better.
            </p>
            <p>
              Sometimes the gaps in your knowledge only become clear when you try explaining things to others. It's OK if no one reads what you write. You get a lot out of just doing it for <strong>you</strong>.
            </p>
            <cite><a href="https://twitter.com/addyosmani/status/1260779133769924608" target="_blank" rel="noopener noreferrer">@addyosmani</a></cite>
          </blockquote>
        </section>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <ShareArticle baseUrl={data.site.siteMetadata.siteUrl} slug={pageContext.slug} />
          <Bio />
          <div
            id='comments-section'
            style={{
              marginBottom: rhythm(2.5)
            }}
          />
          <SubscribeEmail />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <AniLink fade to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </AniLink>
            )}
          </li>
          <li>
            {next && (
              <AniLink fade to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </AniLink>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents(absolute: false)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          publicURL
        }
      }
    }
  }
`
